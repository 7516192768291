import { get, post } from '../request';

// 后台管理根据模板添加医保
export const addInsurancePolicyBySchema = params => post(`/api/v1/admin/home/addInsurancePolicyBySchema`, params);

// 后台管理根据模板添加医保时查询员工信息
export const findInsuranceForPolicySchema = params => post(`/api/v1/admin/home/findInsuranceForPolicySchema`, params);

//后台管理policyManagement列表查询
export const getPolicyBySchemaOnboardList = params => post(`/api/v1/admin/home/getPolicyBySchemaOnboardList`, params);

//后台管理修改医保申请
export const updateInsurancePolicyManagement = params => post(`/api/v1/admin/home/updateInsurancePolicyManagement`, params);

//后台管理查询可选择模板列表
export const getApprovalPolicySchemaList = params => get(`/api/v1/admin/home/getApprovalPolicySchemaList`);

export const getPolicyBySchemaOnboardDetails = params => get(`/api/v1/admin/home/getPolicyBySchemaOnboardDetails/${params}`);

//后台管理删除医保申请
export const deleteInsurancePolicyManagement = params => get(`/api/v1/admin/home/deleteInsurancePolicyManagement/${params}`);
