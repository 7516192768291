import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};
const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
    },
    {
        path: '/page',
        name: 'page',
        component: () => import(/* webpackChunkName: "login" */ '../views/page.vue'),
        children: [
            {
                path: '/staff',
                name: 'staff',
                component: () => import(/* webpackChunkName: "login" */ '../views/staff/index.vue')
            },
            {
                path: '/addStaff',
                name: 'addStaff',
                component: () => import(/* webpackChunkName: "login" */ '../views/staff/addStaff.vue')
            },
            {
                path: '/viewStaff',
                name: 'viewStaff',
                component: () => import(/* webpackChunkName: "login" */ '../views/staff/viewStaff.vue')
            },
            {
                path: '/insurance',
                name: 'insurance',
                component: () => import(/* webpackChunkName: "login" */ '../views/insurance/index.vue')
            },
            {
                path: '/addInsurance',
                name: 'addInsurance',
                component: () => import(/* webpackChunkName: "login" */ '../views/insurance/addInsurance.vue')
            },
            {
                path: '/doctor',
                name: 'doctor',
                component: () => import(/* webpackChunkName: "login" */ '../views/doctor/index.vue')
            },
            {
                path: '/addDoctor',
                name: 'addDoctor',
                component: () => import(/* webpackChunkName: "login" */ '../views/doctor/addDoctor.vue')
            },
            {
                path: '/viewClinic',
                name: 'viewClinic',
                component: () => import(/* webpackChunkName: "login" */ '../views/doctor/viewClinic.vue')
            },
            {
                path: '/approval',
                name: 'approval',
                component: () => import(/* webpackChunkName: "login" */ '../views/Approval/index.vue')
            },
            {
                path: '/details',
                name: 'details',
                component: () => import(/* webpackChunkName: "login" */ '../views/Approval/details.vue')
            },
            {
                path: '/relatives',
                name: 'relatives',
                component: () => import(/* webpackChunkName: "login" */ '../views/relatives/index.vue')
            },
            {
                path: '/add',
                name: 'add',
                component: () => import(/* webpackChunkName: "login" */ '../views/relatives/add.vue')
            },
            {
                path: '/diagnostic',
                name: 'diagnostic',
                component: () => import(/* webpackChunkName: "login" */ '../views/diagnostic/index.vue')
            },
            {
                path: '/viewDiagnostic',
                name: 'viewDiagnostic',
                component: () => import(/* webpackChunkName: "login" */ '../views/diagnostic/viewDiagnostic.vue')
            },
            {
                path: '/disbursement',
                name: 'disbursement',
                component: () => import(/* webpackChunkName: "login" */ '../views/Disbursement/index.vue')
            },
            {
                path: '/disbursementDetails',
                name: 'disbursementDetails',
                component: () => import(/* webpackChunkName: "login" */ '../views/Disbursement/disbursementDetails.vue')
            },
            {
                path: '/disStatus',
                name: 'disStatus',
                component: () => import(/* webpackChunkName: "login" */ '../views/Disbursement/disStatus.vue')
            },
            {
                path: '/clinic',
                name: 'clinic',
                component: () => import(/* webpackChunkName: "login" */ '../views/clinic/index.vue')
            },
            {
                path: '/addClinic',
                name: 'addClinic',
                component: () => import(/* webpackChunkName: "login" */ '../views/clinic/addClinic.vue')
            },
            {
                path: '/Dictionary',
                name: 'Dictionary',
                component: () => import(/* webpackChunkName: "login" */ '../views/Dictionary/index.vue')
            },
            {
                path: '/policySchema',
                name: 'policySchema',
                component: () => import(/* webpackChunkName: "login" */ '../views/policySchema/index.vue')
            },
            {
                path: '/addPolicySchema',
                name: 'addPolicySchema',
                component: () => import(/* webpackChunkName: "login" */ '../views/policySchema/addPolicySchema.vue')
            },
            {
                path: '/policyManagement',
                name: 'policyManagement',
                component: () => import(/* webpackChunkName: "login" */ '../views/policyManagement/index.vue')
            },
            {
                path: '/addPolicyManagement',
                name: 'addPolicyManagement',
                component: () => import(/* webpackChunkName: "login" */ '../views/policyManagement/addPolicyManagement.vue')
            },
            {
                path: '/viewPolicyManagement',
                name: 'viewPolicyManagement',
                component: () => import(/* webpackChunkName: "login" */ '../views/policyManagement/viewPolicyManagement.vue')
            }
        ]
    }
];

const router = new VueRouter({
    routes
});

export default router;
