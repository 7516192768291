import { get, post, downloadFile } from '../request';

// 查询医生审批列表
export const findOnboardList = (params) => post(`/api/v1/admin/home/findOnboardList`, params)

// 医生审批
export const processOnboard = (params) => post(`/api/v1/admin/home/processOnboard`, params)

// 医生详情
export const getOnboardDetails = (params) => get(`/api/v1/admin/home/getOnboardDetails/${params}`)

// 后台管理批量审核onboard
export const processOnboardMany = (params) => post(`/api/v1/admin/home/processOnboardMany`, params)

// 后台管理获取Onboard首页各类审核待处理数量
export const getOnboardTypeList = (params) => get(`/api/v1/admin/home/getOnboardTypeList`)