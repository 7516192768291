import { get, post } from '../request';

// 添加医保模板
export const addPolicySchema = params => post(`/api/v1/admin/home/addPolicySchema`, params);

// 后台管理修改医保模板
export const updatePolicySchema = params => post(`/api/v1/admin/home/updatePolicySchema`, params);

//后台管理查看模板列表
export const getPolicySchemaList = params => post(`/api/v1/admin/home/getPolicySchemaList`, params);

//后台管理删除医保模板
export const deletePolicySchema = params => get(`/api/v1/admin/home/deletePolicySchema/${params}`);

//后台管理获取医保模板详情
export const getPolicySchemaDetails = params => get(`/api/v1/admin/home/getPolicySchemaDetails/${params}`);
